.page-container {
  width: 100vw;
  height: 90vh;
  position: relative;
  top: 10vh;
}

.media-container {
  position: relative;
  display: flex;
  width: 100vw;
  transition-duration: 1s;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  width: 50vw;
}

.list-container ul {
  list-style: none;
  text-align: start;
  display: flex;
  flex-flow: column;
  width: 50vw;
  height: 80vh;
  justify-content: center;
}

li::before {
  content: "\25A0";
  color: #d52b1e;
  /* background-color: #d52b1e; */
  display: inline-block;
  width: 4vh;
  margin: 1vh 0vw -1vh -4vh;
  font-size: 4vh;
}

li {
  padding: 1vw 3vw;
  font-size: 2.5vh;
  overflow: hidden;
}

.footnote {
  font-size: 1vh;
  margin-bottom: 3vh;
}

.science-image-container {
  width: 50vw;
  height: 90vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#secondary {
  background-image: radial-gradient(
    circle farthest-corner at 75vw 50vh,
    #c3d3e5 5%,
    #7d9aaa 10%,
    #485463 35%,
    #03202f 75%
  );
}

.hero-image-container {
  width: 50vw;
  height: 90vh;
  position: relative;
}

.hero-image {
  position: absolute;
  height: 90vh;
  bottom: 0;
  right: 0;
}

.science-image {
  height: 60vh;
  position: relative;
  background: transparent;
}
